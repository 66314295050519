<template>
  <div>
    <div class="card">
      <div class="card-header">
        Project <strong> {{ project.name }}</strong>
      </div>
      <div class="card-body">
        <h5 class="card-title">{{ project.customer }}</h5>
        <h5>Contact details</h5>
        <p class="card-text">
          {{ project.description }}
        </p>
        <address>
          {{ project.contact }}
        </address>

        <a href="#" class="btn btn-primary">Use</a>
        <b-button id="show-btn" @click="showModal">Open Modal</b-button>
        <b-button id="toggle-btn" @click="toggleModal">Toggle Modal</b-button>
      </div>
    </div>

    <b-modal ref="my-modal" hide-footer title="Using Component Methods">
      <div class="d-block text-center">
        <h3>Hello From My Modal!</h3>
      </div>
      <form @submit="updateCustomer">
        <h3>{{ project.name }}</h3>
        <hr />

        <div class="row">
          <input v-model="project.name" placeholder="Name" required readonly />
          <input placeholder="Customer" v-model="project.customer" />
          <input placeholder="Contact" />
          <input type="submit" value="Update" class="btn btn-secondary" />
          <!-- <input type="submit" value="Create" class="btn btn-primary" > -->
          <router-link
            :to="{ name: 'order' }"
            class="btn btn-outline-primary ml-3"
            >Select</router-link
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ProjectDetails",
  props: ["project"],
  methods: {
    ...mapActions(["updateProject"]),
    updateCustomer(e) {
      e.preventDefault();
      this.updateProject(this.project);
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    }
  },
  computed: mapGetters(["loading"])
};
</script>

<style scoped>
input[type="submit"] {
  margin-left: 15px;
}
</style>
