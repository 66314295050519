<template>
  <section>
    <h1>test</h1>
    <Project-Details></Project-Details>
  </section>
</template>

<script>
import ProjectDetails from "@/components/ProjectDetails.vue";

export default {
  name: "OrderDetails",
  components: {
    ProjectDetails
  }
};
</script>

<style></style>
